import API from "../../../../../global/api";
import { get, forEach, isNull, isEmpty, sumBy } from "lodash";
import logo from "../../../../../Images/logo.png";

import jsPDF from "jspdf";
import moment from "moment";
import { textData } from "../../../../../global/test";
import { textData1 } from "../../../../../global/test2";

export const requestGetCustomer = () => {
  return {
    type: "REQUEST_GET_CUSTOMER",
  };
};

export const successGetCustomer = (data) => {
  return {
    type: "SUCCESS_GET_CUSTOMER",
    payload: data,
  };
};

export const errorGetCustomer = () => {
  return {
    type: "ERROR_GET_CUSTOMER",
  };
};
export const getCustomer = () => {
  return (dispatch) => {
    dispatch(requestGetCustomer());
    API.get("/invoice/get_invoice_customer")
      .then((res) => {
        dispatch(successGetCustomer(res.data));
      })
      .catch((error) => {
        dispatch(errorGetCustomer());
      });
  };
};
//delete invoice
export const requestDeleteInvoice = () => {
  return {
    type: "REQUEST_DELETE_INVOICE",
  };
};

export const successDeleteInvoice = (data) => {
  return {
    type: "SUCCESS_DELETE_INVOICE",
    payload: data,
  };
};

export const errorDeleteInvoice = () => {
  return {
    type: "ERROR_DELETE_INVOICE",
  };
};
export const deleteInvoice = (id) => {
  return (dispatch) => {
    dispatch(requestDeleteInvoice());
    API.delete(`/invoice/${id}`)
      .then((res) => {
        dispatch(getInvoice());
        dispatch(successDeleteInvoice(res.data));
      })
      .catch((error) => {
        dispatch(errorDeleteInvoice());
      });
  };
};
//add Ivoice
export const requestAddInvoice = () => {
  return {
    type: "REQUEST_ADD_INVOICE",
  };
};
export const successAddInvoice = (data) => {
  return {
    type: "SUCCESS_ADD_INVOICE",
    payload: data,
  };
};
export const errorAddInvoice = () => {
  return {
    type: "ERROR_ADD_INVOICE",
  };
};
export const addInvoice = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddInvoice());
    API.post(`/invoice/add_invoice`, data)
      .then((res) => {
        dispatch(getInvoice());
        dispatch(successAddInvoice(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddInvoice());
        handleDangerVisible();
      });
  };
};
//Update Ivoice
export const requestUpdateInvoice = () => {
  return {
    type: "REQUEST_UPDATE_INVOICE",
  };
};
export const successUpdateInvoice = (data) => {
  return {
    type: "SUCCESS_UPDATE_INVOICE",
    payload: data,
  };
};
export const errorUpdateInvoice = () => {
  return {
    type: "ERROR_UPDATE_INVOICE",
  };
};
export const updateInvoice = (id,data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestUpdateInvoice());
    API.put(`/invoice/update_invoice/${id}`, data)
      .then((res) => {
        dispatch(getInvoice());
        dispatch(successUpdateInvoice(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorUpdateInvoice());
        handleDangerVisible();
      });
  };
};
///get Ivoice By Id
export const requestGetInvoiceById = () => {
  return {
    type: "REQUEST_GET_INVOICE_BY_ID",
  };
};

export const successGetInvoiceById = (data) => {
  return {
    type: "SUCCESS_GET_INVOICE_BY_ID",
    payload: data,
  };
};

export const errorGetInvoiceById = () => {
  return {
    type: "ERROR_GET_INVOICE_BY_ID",
  };
};
export const getInvoiceById = (id) => {
  return (dispatch) => {
    dispatch(requestGetInvoiceById());
    API.get(`/invoice/get_invoice_withID/${id}`)
      .then((res) => {
        dispatch(successGetInvoiceById(res.data));
      })
      .catch((error) => {
        dispatch(errorGetInvoiceById());
      });
  };
};
///get Ivoice
export const requestGetInvoice = () => {
  return {
    type: "REQUEST_GET_INVOICE",
  };
};

export const successGetInvoice = (data) => {
  return {
    type: "SUCCESS_GET_INVOICE",
    payload: data,
  };
};

export const errorGetInvoice = () => {
  return {
    type: "ERROR_GET_INVOICE",
  };
};
export const getInvoice = () => {
  return (dispatch) => {
    dispatch(requestGetInvoice());
    API.get("/invoice/get_invoice")
      .then((res) => {
        dispatch(successGetInvoice(res.data));
      })
      .catch((error) => {
        dispatch(errorGetInvoice());
      });
  };
};
//pdf
export const getInvoicePdf = (data) => {
  console.log(data,"testttttttttttt")
  return (dispatch) => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";// portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.addFileToVFS('Amiri-Bold.ttf', textData1);
    doc.addFont('Amiri-Bold.ttf', 'Amiri-Bold', 'normal');
    doc.setFont('Amiri-Regular');
    doc.setFontSize(11);
    const addFooters = doc => {
      const pageCount = doc.internal.getNumberOfPages()
    console.log(doc.internal.pageSize.height,"llllllllllllllllllllll")
      doc.setFont('helvetica', 'italic')
      doc.setFontSize(14)
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width / 2, doc.internal.pageSize.height-10, {
          align: 'center'
        })
      }
    }
    const title = "Invoice Report";
    const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
    const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
    const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
    const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
    const customerName = !isEmpty(data.level_five.levelFiveName)
      ? "Customer Name: " + data.level_five.levelFiveName
      : "Customer Name: - ";
      const customerBalance=data.invoice_status=="Posted"?data.level_five.levelBalance:data.level_five.levelBalance+Number(data.invoice_total_amount)
    // const demandName = "Receiving Name: " + issuanceName;
    // const IGPNumber = "Receiving #: " + resData.map((x) => x.receiving_id);
    const invoiceDate = !isEmpty(data.invoice_date)
      ? "Invoice Date: " + data.invoice_date
      : "Invoice Date: - ";
    const grandTotal = "Grand Total: " + data.invoice_total_amount;

    const headers = [
      [
        "PRODUCT NAME",
        "INVENTORY PRICE",
        "INVOICE QUANTITY",
        "INVOICE ADDONS",
        "INVOICE TOTAL PRICE",
      ],
    ];
    const data1 = data.invoice_data.map((elt) => [
      elt.inventory.productName,
      // elt.inventory.productPrice,
      Number(elt.total_price_inv)/Number(elt.invoice_data_quantity),
      // elt.invoice_data_price,
      elt.invoice_data_quantity,
      elt.invoice_addons.map((x)=>{return x.label+"\n"}),
      elt.total_price_inv,

      // Number(elt.inventory.productPrice) * Number(elt.invoice_data_quantity),
    ]);
    let content = {
      startY: 140,
      startX: 10,
      head: headers,
      // tableWidth: "wrap",
      body: data1,
      margin: { left: 5, right: 5 },
      styles: {
        font: 'Amiri-Bold',
        fontStyle: 'normal',
        fontWeight:'bold',
        fontSize:12,
      }
    };
    // doc.addImage(logo, "PNG", 40, 15, 100, 100);
    doc.setFontSize(20);
    // doc.text(companyName, 220, 35, 0, 20);
    // doc.textWithLink('www.superfinebicycles.com', 200, 55, { url: 'http://superfinebicycles.com' })
    doc.text(title, 240, 77);
    doc.setFontSize(13);
    // doc.setFont("times", "normal");
    doc.setFont('Amiri-Regular');
    // doc.text(companyAddress, 150, 55, 0, 20);
    // doc.text(companyNtn, 150, 75, 0, 20);
    // doc.text(`Ph: ${companyPhone}`, 150, 95, 0, 20);
    doc.setFontSize(14);
    // doc.setFont("times", "bold");
    doc.setFont('Amiri-Regular');
    
    // doc.setFont("times", "normal");
    doc.setFont('Amiri-Regular');
    doc.text(customerName, 10, 100, 0, 20);
    // doc.text(`Customer Balance: ${data.level_five.levelBalance} ${data.level_five.closing_balance_sign==2?"Debit":"Credit"}`,40,170,0,20)
    doc.text(invoiceDate, 400, 100, 0, 20);
    doc.text(`Additional Description: ${data.invoice_desc}`, 10, 120, 0, 20);
    doc.text(`Invoice ID: ${data.invoice_id}`, 400, 120, 0, 20);
    // doc.text(demandName, 200, 80, 0, 20);
    // doc.text(IGPNumber, 200, 120, 0, 20);

    doc.autoTable(content);
    addFooters(doc)
//     const totalPagesExp = "{total_pages_count_string}";

// doc.autoTable(columns, data, {
//   addPageContent: data => {
//     let footerStr = "Page " + doc.internal.getNumberOfPages();
//     if (typeof doc.putTotalPages === 'function') {
//       footerStr = footerStr + " of " + totalPagesExp;
//     }
//     doc.setFontSize(10);
//     doc.text(footerStr, data.settings.margin.left, doc.internal.pageSize.height - 10);
//   }
// });

// if (typeof doc.putTotalPages === 'function') {
//   doc.putTotalPages(totalPagesExp);
// }
console.log(doc.internal.pageSize.height,doc.lastAutoTable.finalY,"aaaaaaaqqqqqqqqqqq")
    let finalY = doc.lastAutoTable.finalY>760?5:doc.lastAutoTable.finalY-20;
    // doc.text(grandTotal, 440, finalY + 20);
    doc.setFontSize(16);
    doc.lastAutoTable.finalY>760&&doc.addPage()
    doc.text("Previous Balance:",350,finalY+40)
    doc.text("Invoice Amount:",350,finalY+60)
    doc.text("Total Balance:",350,finalY+80)
    data.invoice_status=="Posted"?doc.text(`${data.level_five.opening_balance} ${data.level_five.opening_balance_sign==2?"Debit":"Credit"}`,480,finalY+40):doc.text(`${data.level_five.accBalance} ${data.level_five.accBalance_sign==2?"Debit":"Credit"}`,480,finalY+40)
    doc.text(`${data.invoice_total_amount}`,480,finalY+60)
    data.invoice_status=="Posted"?doc.text(`${data.level_five.accBalance} ${data.level_five.accBalance_sign==2?"Debit":"Credit"}`,480,finalY+80):doc.text(`${data.level_five.accBalance} ${data.level_five.accBalance_sign==2?"Debit":"Credit"}`,480,finalY+80)
    data.invoice_status=="Pending"&&doc.text(`As the invoice is pending, the total balance is unaffected by the invoice balance.`,70,finalY+100)
    doc.setProperties({
      title: `Invoice Report ${moment(new Date()).format("YYYY-MM-DD")}`,
    });
    var blob = doc.output("blob");
    window.open(URL.createObjectURL(blob));
    // doc.save(`Invoice Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`);
  };
};
////post Invoice
export const requestPostInvoice = () => {
  return {
    type: "REQUEST_POST_INVOICE",
  };
};
export const successPostInvoice = (data) => {
  return {
    type: "SUCCESS_POST_INVOICE",
    payload: data,
  };
};
export const errorPostInvoice = () => {
  return {
    type: "ERROR_POST_INVOICE",
  };
};
export const PostInvoice = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestPostInvoice());
    API.post(`/invoice/invoice_post`, data)
      .then((res) => {
        dispatch(getInvoice());
        dispatch(successPostInvoice(res.data));
        //   handleVisible();
      })
      .catch((error) => {
        dispatch(errorPostInvoice());
        //   handleDangerVisible();
      });
  };
};

export const requestInoviceSummaryReport = () => {
  return {
    type: "REQUEST_INVOIVE_SUMMARY_REPORT",
  };
};
export const successInoviceSummaryReport = (data) => {
  return {
    type: "SUCCESS_INVOIVE_SUMMARY_REPORT",
    payload: data,
  };
};
export const errorInoviceSummaryReport = () => {
  return {
    type: "ERROR_INVOIVE_SUMMARY_REPORT",
  };
};

export const inoviceSummaryReport = (
  data,
  startDate,
  endDate,
  handleVisible
) => {
  return (dispatch) => {
    dispatch(requestInoviceSummaryReport());
    API.post(`/invoice/invoice_product_variation_report`, data)
      .then((res) => {
        // dispatch(getIssuance());
        let resData = res.data;
        if(isEmpty(resData)) { handleVisible();}
        else{
        console.log(resData,'tttttttttttttttttt')
        const unit = "pt";
        const size = "A4";
        const orientation = "landscape";//potrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(11);
        const title = "Invoice Summary";
        const companyName = `Company Name: ${process.env.REACT_APP_COMPANY_NAME}`;

        const Dates =
          !isEmpty(startDate) || !isEmpty(endDate)
            ? "Invoice Report From " + " " + startDate + " to " + endDate
            : "";
        doc.addFileToVFS('Amiri-Regular.ttf', textData);
        doc.addFont('Amiri-Regular.ttf', 'Amiri-Regular', 'normal');
        doc.setFont('Amiri-Regular');
        const headers = [
          [
            "Warehouse",
            "Inventory Name",
            "Model",
            "Color",
            "Size",
            "Price",
            "Quantity",
            "Total",
          ],
        ];

        const data = resData.map((elt) => [
          elt.inventory.warehouse.wareHouseName,
          elt.inventory.productName,
          elt.inventory.inv_variation_model.name,
          elt.inventory.inv_variation_colour.name,
          elt.inventory.inv_variation_size.name,
          elt.invoice_data_price,
          elt.invoice_data_quantity,
          elt.total_price_inv
        ]);
        data.push(["","","","","Total",sumBy(data,'5'),sumBy(data,'6'),sumBy(data,'7')])
        let content = {
          startY: 120,
          startX: 10,
          head: headers,
          // columnStyles: {
          //   0: { cellWidth: 40 },
          //   1: { cellWidth: 190 },
          //   2: { cellWidth: 50 },
          //   3: { cellWidth: 60 },
          //   4: { cellWidth: 60 },
          //   5: { cellWidth: 60 },
          //   6: { cellWidth: 60 }

          // },

          styles: {
            font: 'Amiri-Regular',
            fontStyle: 'normal',
          },
          // cellWidth: 'wrap',
          body: data,
        };
        doc.setFontSize(16)
        doc.setFont('Amiri-Regular',"bold")
        // doc.addImage(logo, "PNG", 40, 15, 100, 100);
        doc.text(companyName, 300, 50, 0, 20);
        doc.setFontSize(14)
        doc.setFont('Amiri-Regular',"normal")
        // doc.text(WareHouseName, 200, 80, 0, 20);
        doc.text(Dates, 290, 80, 0, 20);
        doc.text(title, 360, 100);
        doc.autoTable(content);
        // doc.save(
        //   `${!isEmpty(name) && name} Inventory Report ${moment(
        //     new Date()
        //   ).format("YYYY-MM-DD")}.pdf`
        // );
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));
      }
        dispatch(successInoviceSummaryReport(resData));
      })
      .catch((error) => {
        dispatch(errorInoviceSummaryReport());
      });
  };
};

export const setEditFields = (data) => {
  console.log('data', data)
  return {
      type: "SET_EDIT_FIELDS_Invoice",
      payload: data
  };
}
